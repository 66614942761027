import React from 'react'

import { IoLogoWhatsapp } from 'react-icons/io'

import atendimento from 'assets/svg/atendimento.svg'
import { Modal, Button } from 'components'

import styles from './HelpModal.module.scss'

export type HelpModalProps = {
  isOpen: boolean
  closeModal: () => void
  showTutorialButton?: boolean
  questions?: { label: string; url: string }[]
  onClick?: () => void
}

export const HelpModal: React.FC<HelpModalProps> = ({
  isOpen,
  closeModal,
  questions = [],
  showTutorialButton = true,
  onClick
}) => {
  return (
    <Modal
      typeModal='small'
      isOpen={isOpen}
      closeModal={closeModal}
      closeButton={true}
    >
      <div className={styles.container}>
        <img src={atendimento} alt='icone de atendimento para ajuda' />
        <h1>Precisa de ajuda?</h1>
        <p>Listamos alguns conteúdos que podem te ajudar.</p>
        <div className={styles.questionsWrapper}>
          {questions.map((question, index) => (
            <a
              key={index.toString()}
              href={question.url}
              className={styles.question}
              target='_blank'
              rel='noopener noreferrer'
            >
              {question.label}
            </a>
          ))}
          {showTutorialButton && (
            <Button
              label='Ver tutorial passo a passo na página'
              typeButton={'primary'}
              onClick={onClick}
            />
          )}
        </div>
        <footer className={styles.footer}>
          <p>
            Ainda com dúvida?
            <br />
            Entre em contato pelos nossos canais de atendimento
          </p>
          <div className={styles.buttons}>
            <div>
              <IoLogoWhatsapp size={42} color='#64b161' />
              <p>Atendimento por Whatsapp</p>
            </div>
            <div>
              <img src={atendimento} alt='icone de atendimento para ajuda' />
              <p>(19) 3301-8535</p>
            </div>
          </div>
        </footer>
      </div>
    </Modal>
  )
}
