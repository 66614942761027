import { useState, useEffect, useCallback } from 'react'

import { toast } from 'react-toastify'

import { ProposalModel } from '_types'
import { listProposal, cancelProposal } from 'services/api'

export type UseProposalList = {
  proposals: ProposalModel[]
  loading: boolean
  handleCancelProposal: (id: string) => Promise<void>
}

export function useProposalList(): UseProposalList {
  const [proposals, setProposals] = useState<ProposalModel[]>([])
  const [loading, setLoading] = useState(false)

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await listProposal()
      setProposals(response)
    } catch (error) {
      toast.error('Erro na listagem de propostas')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleCancelProposal = async (id: string) => {
    setLoading(true)
    await cancelProposal({ id })
    setLoading(false)
  }
  return {
    proposals,
    loading,
    handleCancelProposal
  }
}
