import React from 'react'

import { Form } from 'components'
import {
  isValidFullName,
  isValidCpf,
  isValidRg,
  isValidEmail
} from 'utils/validators'

import styles from './WitnessForm.module.scss'

export type WitnessFormProps = {
  title?: string
  fields: any
  handleChange: (field: string) => (value: string) => void
}

export const WitnessForm: React.FC<WitnessFormProps> = ({
  fields,
  handleChange
}) => {
  const witnessDatafields = [
    {
      label: 'Nome completo',
      type: 'text',
      value: fields.witness.name,
      onChange: handleChange('name'),
      isValid: isValidFullName,
      required: true,
      capitalize: true,
      size: 'medium'
    },
    {
      label: 'Email',
      type: 'email',
      value: fields.witness.email,
      onChange: handleChange('email'),
      isValid: isValidEmail,
      required: true,
      size: 'medium'
    },
    {
      label: 'CPF',
      type: 'text',
      value: fields.witness.cpf,
      onChange: handleChange('cpf'),
      isValid: isValidCpf,
      mask: '***.***.***-**',
      required: true,
      size: 'medium'
    },
    {
      label: 'RG',
      type: 'text',
      value: fields.witness.rg,
      onChange: handleChange('rg'),
      isValid: isValidRg,
      mask: '**.***.***-*',
      required: true,
      size: 'medium'
    }
  ]
  return (
    <div className={styles.container}>
      <h1>Dados da testemunha</h1>
      <div>
        <Form fields={witnessDatafields} />
      </div>
    </div>
  )
}
