import React, { useState } from 'react'

import classnames from 'classnames'
import { IoIosArrowForward } from 'react-icons/io'

import { SmallProponentModel } from '_types'
import { BottomNavigation, Button } from 'components'

import styles from './DocumentationForm.module.scss'
import { ComplementaryStep } from './steps/ComplementaryStep'
import { PersonalStep } from './steps/PersonalStep'
import { SpouseStep } from './steps/SpouseStep'

const Steps = {
  PERSONAL: '__PERSONAL__',
  SPOUSE: '__SPOUSE__',
  COMPLEMENTARY: '__COMPLEMENTARY__'
}

type DocumentationForm = {
  proponent: SmallProponentModel
  handleCloseForm: () => void
  isVisibleHeader?: boolean
}

export const DocumentationForm: React.FC<DocumentationForm> = ({
  proponent,
  handleCloseForm,
  isVisibleHeader = true
}) => {
  const [activeStep, setActiveStep] = useState(Steps.PERSONAL)

  const stepsContent = {
    [Steps.PERSONAL]: (
      <PersonalStep
        personalDocuments={proponent.personalDocuments}
        idProponent={proponent.id}
      />
    ),
    [Steps.SPOUSE]: (
      <SpouseStep
        spouseDocuments={proponent.spouseDocuments}
        idProponent={proponent.id}
      />
    ),
    [Steps.COMPLEMENTARY]: (
      <ComplementaryStep
        complementaryDocuments={proponent.complementaryDocuments}
        idProponent={proponent.id}
      />
    )
  }

  const stepsNav = {
    [Steps.PERSONAL]: { title: 'Documentos pessoais - 2/7' },
    [Steps.SPOUSE]: { title: 'Documentos do cônjuge - 0/7' },
    [Steps.COMPLEMENTARY]: { title: 'Documentos complementares - 0/3' }
  }

  function onChangeStep(step: string) {
    setActiveStep(step)
  }

  function activeStepClassName(step: string) {
    return activeStep === step ? styles.activeStep : ''
  }

  function onNextStep() {
    if (activeStep === Steps.PERSONAL) return setActiveStep(Steps.SPOUSE)
    if (activeStep === Steps.SPOUSE) return setActiveStep(Steps.COMPLEMENTARY)
  }

  return (
    <div className={styles.container}>
      <div className={classnames({ [styles.none]: !isVisibleHeader })}>
        <h1>Documentos {proponent.name}</h1>
        <header>
          <span>
            Você pode arrastar seus documentos para a área indicada ou clicar
            para carregar arquivos do seu computador
          </span>
          <p>
            O arquivo não deve exceder 25mb. Formatos aceitos: PDF, JPG, PNG.
          </p>
        </header>
      </div>
      <nav>
        {Object.entries(stepsNav).map(([step, { title }]) => (
          <button
            key={step}
            className={activeStepClassName(step)}
            onClick={() => onChangeStep(step)}
          >
            <div>{title}</div>
          </button>
        ))}
      </nav>

      {stepsContent[activeStep]}

      <div className={styles.bottomNavigation}>
        <BottomNavigation
          mainButton={
            <Button
              label='Próximo'
              onClick={onNextStep}
              icon={<IoIosArrowForward size='16' />}
            />
          }
          secondaryButton={
            <Button
              typeButton='underlined'
              label='Salvar e finalizar depois'
              onClick={handleCloseForm}
            />
          }
        />
      </div>
    </div>
  )
}
