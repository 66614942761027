import React from 'react'

import { ProponentModel } from '_types'
import documents from 'assets/svg/documentos.svg'
import { DocumentationListModal, ProponentDocument } from 'components'
import {
  DocumentationForm,
  DeclinedDocumentationForm,
  PropertyContainer,
  ProponentsForm
} from 'containers'
import { useToggle } from 'hooks/useToogle'
import { useProposal } from 'store/hooks'

import styles from './DocumentationContainer.module.scss'

export const DocumentationContainer: React.FC = () => {
  const {
    proposal: { proponents }
  } = useProposal()

  const documentationModalToggle = useToggle<ProponentModel>()
  const documentationFormToggle = useToggle<ProponentModel>()
  const declinedDocumentationFormToggle = useToggle<ProponentModel>()
  const editProponentFormToggle = useToggle<ProponentModel>()

  if (documentationFormToggle.isOpen) {
    return (
      <DocumentationForm
        proponent={documentationFormToggle.params}
        handleCloseForm={documentationFormToggle.close}
      />
    )
  }

  if (declinedDocumentationFormToggle.isOpen) {
    return (
      <DeclinedDocumentationForm
        proponent={declinedDocumentationFormToggle.params}
        handleCloseForm={declinedDocumentationFormToggle.close}
      />
    )
  }

  if (editProponentFormToggle.isOpen) {
    return (
      <ProponentsForm
        handleClose={editProponentFormToggle.close}
        initialData={editProponentFormToggle.params}
      />
    )
  }

  return (
    <>
      <div className={styles.documentationContainer}>
        <header>
          <img src={documents} alt='Ícone de um documento' />
          <h1>Documentos</h1>
        </header>
        <p>
          Para continuar com a proposta você precisa finalizar o cadastro do
          proponente principal
        </p>

        {/* TODO: Implementar listagem e ações para os documentos dos proponentes  */}
        <div className={styles.proponentList}>
          {proponents.map(p => (
            <ProponentDocument
              key={p.id}
              onEditDocuments={() => documentationFormToggle.open(p)}
              onReprovedDocuments={() =>
                declinedDocumentationFormToggle.open(p)
              }
              onShowDocuments={() => documentationModalToggle.open(p)}
              proponent={p}
              onEdit={() => editProponentFormToggle.open(p)}
            />
          ))}
        </div>
      </div>

      <div className={styles.card}>
        <PropertyContainer />
      </div>

      {documentationModalToggle.isOpen && (
        <DocumentationListModal
          isOpen={documentationModalToggle.isOpen}
          closeModal={documentationModalToggle.close}
          proponent={documentationModalToggle.params}
        />
      )}
    </>
  )
}
