import React from 'react'

import classnames from 'classnames'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import dashboardIcon from 'assets/svg/dashboardIcon.svg'
import logo from 'assets/svg/logoPagImovel.svg'
import profileIcon from 'assets/svg/profileIcon.svg'
import { Routes } from 'router/routes'

import styles from './Header.module.scss'

export type HeaderProps = {
  mobile?: boolean
  showLinkToDashboard?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  showLinkToDashboard = false,
  mobile = false,
  children
}) => {
  const [openProfile, setOpenProfile] = React.useState(false)

  return (
    <header
      aria-label='header principal'
      className={classnames(styles.header, { [styles.mobile]: mobile })}
    >
      <span className={styles.icon}>{children}</span>
      {showLinkToDashboard && (
        <Link
          to={Routes.PROPOSALS()}
          className={classnames(styles.listItem, styles.show)}
        >
          <img src={dashboardIcon} alt='Ícone de casa indicando o dashboard' />
        </Link>
      )}
      <div className={styles.logoContainer}>
        <Link to='/'>
          <img src={logo} alt='Logo PagImovel' />
        </Link>
      </div>
      <nav className={styles.navbar}>
        <ul>
          <Link
            to={Routes.PROPOSALS()}
            className={classnames(styles.listItem, styles.noShow)}
          >
            <img
              src={dashboardIcon}
              alt='Ícone de casa indicando o dashboard'
              className={styles.dashboardIcon}
            />
            Ir para o dashboard
          </Link>
          <li>
            <button
              onClick={() => {
                setOpenProfile(!openProfile)
              }}
              className={styles.listItem}
            >
              <img src={profileIcon} alt='Ícone de pessoa indicando o perfil' />
              <>
                <span
                  className={classnames(styles.profileLabel, styles.noShow)}
                >
                  Meu perfil
                </span>
                <RiArrowDropDownLine
                  color='#444444'
                  size={16}
                  className={styles.noShow}
                />
              </>
            </button>
            {openProfile && (
              <div className={styles.profile}>
                <span>
                  <ul>
                    <li>Opcao 1</li>
                    <li>Opcao 2</li>
                    <li>Opcao 3</li>
                  </ul>
                </span>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </header>
  )
}
