import React from 'react'

import { SmallProponentModel, ProponentModel } from '_types'
import {
  ProponentDocument,
  AddProponent,
  Loader,
  DocumentationListModal
} from 'components'
import {
  DeclinedDocumentationForm,
  DocumentationForm,
  ProponentsForm
} from 'containers'
import { useToggle } from 'hooks/useToogle'
import { getProponentData, deleteProponent } from 'services/api'
import { useUser } from 'store/hooks'
import { BaseLayoutWithSideMenu } from 'template'

import styles from './SavedProponents.module.scss'
import { useSavedProponents } from './useSavedProponents'

export function SavedProponentsPage(): JSX.Element {
  const { proponents, loading, loadData, setLoading } = useSavedProponents()
  const { user } = useUser()

  const documentationModalToggle = useToggle<SmallProponentModel>()
  const documentationFormToggle = useToggle<SmallProponentModel>()
  const declinedDocumentationFormToggle = useToggle<SmallProponentModel>()
  const proponentFormToggle = useToggle()
  const editProponentFormToggle = useToggle<ProponentModel>()

  const handleEditProponent = async (id: string) => {
    setLoading(true)

    try {
      const proponent = await getProponentData({ id })
      editProponentFormToggle.open(proponent)
    } catch (error) {}

    setLoading(false)
  }

  const handleDeleteProponent = async (id: string) => {
    await deleteProponent({ id })
    loadData()
  }

  if (loading) {
    return (
      <BaseLayoutWithSideMenu>
        <Loader />
      </BaseLayoutWithSideMenu>
    )
  }
  if (proponentFormToggle.isOpen) {
    return (
      <BaseLayoutWithSideMenu>
        <ProponentsForm handleClose={proponentFormToggle.close} />
      </BaseLayoutWithSideMenu>
    )
  }

  if (documentationFormToggle.isOpen) {
    return (
      <BaseLayoutWithSideMenu>
        <DocumentationForm
          proponent={documentationFormToggle.params}
          handleCloseForm={documentationFormToggle.close}
        />
      </BaseLayoutWithSideMenu>
    )
  }

  if (declinedDocumentationFormToggle.isOpen) {
    return (
      <BaseLayoutWithSideMenu>
        <DeclinedDocumentationForm
          proponent={declinedDocumentationFormToggle.params}
          handleCloseForm={declinedDocumentationFormToggle.close}
        />
      </BaseLayoutWithSideMenu>
    )
  }

  if (editProponentFormToggle.isOpen) {
    return (
      <BaseLayoutWithSideMenu>
        <ProponentsForm
          handleClose={editProponentFormToggle.close}
          initialData={editProponentFormToggle.params}
        />
      </BaseLayoutWithSideMenu>
    )
  }

  return (
    <BaseLayoutWithSideMenu>
      <section className={styles.container}>
        <article className={styles.info}>
          <h1>Meus dados cadastrais</h1>
          <ProponentDocument
            onEditDocuments={() => documentationFormToggle.open(user.proponent)}
            onReprovedDocuments={() =>
              declinedDocumentationFormToggle.open(user.proponent)
            }
            onShowDocuments={() =>
              documentationModalToggle.open(user.proponent)
            }
            proponent={user.proponent}
            onEdit={() => editProponentFormToggle.open(user.proponent)}
          />
          <h1 className={styles.secondTitle}>Outros proponentes</h1>
        </article>
        <article className={styles.savedProponents}>
          <div className={styles.othersProponents}>
            <AddProponent onClick={proponentFormToggle.open} />
            {proponents.map(p => (
              <ProponentDocument
                key={p.id}
                onEditDocuments={() => documentationFormToggle.open(p)}
                onReprovedDocuments={() =>
                  declinedDocumentationFormToggle.open(p)
                }
                onShowDocuments={() => documentationModalToggle.open(p)}
                proponent={p}
                onEdit={() => handleEditProponent(p.id)}
                onDelete={() => handleDeleteProponent(p.id)}
              />
            ))}
          </div>
        </article>
      </section>

      {documentationModalToggle.isOpen && (
        <DocumentationListModal
          isOpen={documentationModalToggle.isOpen}
          closeModal={documentationModalToggle.close}
          proponent={documentationModalToggle.params}
        />
      )}
    </BaseLayoutWithSideMenu>
  )
}
