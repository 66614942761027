import React from 'react'

import { Form } from 'components'
import { genderOptions, ufOptions } from 'utils/selectOptions'
import {
  isValidFullName,
  isValidCpf,
  isValidRg,
  isValidBirthdayDate,
  isValidPhone,
  isValidEmail,
  isValidCep
} from 'utils/validators'

import styles from './SpouseForm.module.scss'

export type SpouseFormProps = {
  title?: string
  fields: any
  handleChange: (field: string) => (value: string | boolean) => void
}

export const SpouseForm: React.FC<SpouseFormProps> = ({
  fields,
  handleChange
}) => {
  const personalDatafields = [
    {
      label: 'Nome completo',
      type: 'text',
      value: fields.spouse.name,
      onChange: handleChange('name'),
      isValid: isValidFullName,
      required: true,
      capitalize: true,
      size: 'large'
    },
    {
      label: 'CPF',
      type: 'text',
      value: fields.spouse.cpf,
      onChange: handleChange('cpf'),
      isValid: isValidCpf,
      mask: '***.***.***-**',
      required: true,
      size: 'small'
    },
    {
      label: 'RG',
      type: 'text',
      value: fields.spouse.rg,
      onChange: handleChange('rg'),
      isValid: isValidRg,
      mask: '**.***.***-*',
      required: true,
      size: 'small'
    },
    {
      label: 'Emissor',
      type: 'text',
      value: fields.spouse.emitter,
      onChange: handleChange('emitter'),
      required: true,
      size: 'xsmall'
    },
    {
      label: 'Data de nascimento',
      type: 'text',
      value: fields.spouse.birthday_date,
      onChange: handleChange('birthday_date'),
      isValid: isValidBirthdayDate,
      required: true,
      size: 'xsmall',
      mask: '**/**/****'
    },
    {
      label: 'Profissão',
      type: 'text',
      value: fields.spouse.occupation,
      onChange: handleChange('occupation'),
      required: true,
      size: 'small'
    },
    {
      label: 'Nacionalidade',
      type: 'text',
      value: fields.spouse.nationality,
      onChange: handleChange('nationality'),
      required: true,
      size: 'small'
    },
    {
      label: 'Naturalidade',
      type: 'text',
      value: fields.spouse.naturalness,
      onChange: handleChange('naturalness'),
      required: true,
      size: 'small'
    },
    {
      type: 'select',
      label: 'Sexo',
      selected: fields.spouse.gender,
      onChange: handleChange('gender'),
      options: genderOptions,
      size: 'small'
    }
  ]

  const contactFields = [
    {
      label: 'Whatsapp',
      type: 'tel',
      value: fields.spouse.whatsapp,
      onChange: handleChange('whatsapp'),
      isValid: isValidPhone,
      mask: '(**) *****-****',
      required: true,
      size: 'small'
    },
    {
      label: 'Email',
      type: 'email',
      value: fields.spouse.email,
      onChange: handleChange('email'),
      isValid: isValidEmail,
      required: true,
      size: 'small'
    }
  ]

  const addressFields = [
    {
      type: 'radio',
      label: '',
      size: 'xlarge',
      options: [
        {
          name: 'address_status',
          option: 'Usar o mesmo endereço do proponente',
          checked: fields.spouse.use_proponent_address,
          type: 'toggle',
          onChange: () => handleChange('use_proponent_address')
        }
      ]
    },
    {
      label: 'Rua/Avenida',
      type: 'text',
      value: fields.spouse.use_proponent_address
        ? fields.street
        : fields.spouse.street,
      onChange: handleChange('street'),
      required: true,
      size: 'large',
      disabled: fields.spouse.use_proponent_address
    },
    {
      label: 'Número',
      type: 'number',
      value: fields.spouse.use_proponent_address
        ? fields.number
        : fields.spouse.number,
      onChange: handleChange('number'),
      required: true,
      size: 'xsmall',
      disabled: fields.spouse.use_proponent_address
    },
    {
      label: 'Complemento',
      type: 'text',
      value: fields.spouse.use_proponent_address
        ? fields.complement
        : fields.spouse.complement,
      onChange: handleChange('complement'),
      size: 'xsmall',
      disabled: fields.spouse.use_proponent_address
    },
    {
      label: 'Bairro',
      type: 'text',
      value: fields.spouse.use_proponent_address
        ? fields.neighborhood
        : fields.spouse.neighborhood,
      onChange: handleChange('neighborhood'),
      required: true,
      size: 'small',
      disabled: fields.spouse.use_proponent_address
    },
    {
      label: 'Cidade',
      type: 'text',
      value: fields.spouse.use_proponent_address
        ? fields.city
        : fields.spouse.city,
      onChange: handleChange('city'),
      required: true,
      size: 'small',
      disabled: fields.spouse.use_proponent_address
    },
    {
      label: 'UF',
      type: 'select',
      selected: fields.spouse.use_proponent_address
        ? fields.uf
        : fields.spouse.uf,
      onChange: handleChange('uf'),
      required: true,
      size: 'xsmall',
      options: ufOptions,
      disabled: fields.spouse.use_proponent_address
    },
    {
      label: 'CEP',
      type: 'text',
      value: fields.spouse.use_proponent_address
        ? fields.postal_code
        : fields.spouse.postal_code,
      onChange: handleChange('postal_code'),
      mask: '*****-***',
      isValid: isValidCep,
      required: true,
      size: 'xsmall',
      disabled: fields.spouse.use_proponent_address
    }
  ]

  return (
    <div className={styles.container}>
      <h1>Dados do cônjuge</h1>
      <div>
        <Form fields={personalDatafields} />
        <Form
          title='Contato'
          className={styles.contact}
          fields={contactFields}
        />
        <Form
          title='Endereço'
          className={styles.address}
          fields={addressFields}
        />
      </div>
    </div>
  )
}
