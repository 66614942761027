import React, { useState } from 'react'

import classnames from 'classnames'
import { IconType } from 'react-icons'
import { BsExclamation } from 'react-icons/bs'
import { FaExclamationCircle } from 'react-icons/fa'
import { HiCheck, HiOutlineCheckCircle } from 'react-icons/hi'

import { SmallProponentModel } from '_types'
import colors from 'assets/styles/partials/variables.scss'
import { ReactComponent as OptionsIcon } from 'assets/svg/options.svg'
import { Button, ProgressBar } from 'components'

import styles from './ProponentDocument.module.scss'
export type ProponentDocumentProps = {
  onEditDocuments: () => void
  onReprovedDocuments: () => void
  onShowDocuments: () => void
  proponent: SmallProponentModel
  onEdit: () => void
  onDelete?: () => void
}

export const ProponentDocument: React.FC<ProponentDocumentProps> = ({
  onEditDocuments,
  onReprovedDocuments,
  onShowDocuments,
  proponent,
  onEdit,
  onDelete
}) => {
  const [openOptions, setOpenOptions] = useState(false)
  const { name, statusDocumentation } = proponent
  const { status, progress } = statusDocumentation
  const isFinished = progress >= 100

  const buttonLabel = () => {
    if (status === 'reproved') return 'Reenviar documentos'
    if (isFinished) return 'Editar documentos'
    return 'Anexar documentos'
  }

  const cardOptions = [
    { option: 'edit', label: 'Editar dados', action: () => onEdit() }
  ]

  if (onDelete) {
    cardOptions.push({
      option: 'delete',
      label: 'Deletar proponente',
      action: () => {
        onDelete()
      }
    })
  }

  return (
    <div
      className={classnames(styles.container, {
        [styles['container-finished']]: isFinished,
        [styles['container-reproved']]: status === 'reproved',
        [styles['container-incomplete']]: !isFinished
      })}
    >
      <div>
        <h1>
          {name}
          <OptionsIcon
            className={styles.options}
            onClick={() => setOpenOptions(prev => !prev)}
          />
          {openOptions && (
            <ul className={styles.listOptions}>
              {cardOptions.map(item => (
                <section key={item.option}>
                  <li onClick={() => item.action()}>{item.label}</li>
                  <hr />
                </section>
              ))}
            </ul>
          )}
        </h1>
        <Status status={status} />
        <button className={styles.link} onClick={onShowDocuments}>
          Ver lista de documentos
        </button>
      </div>
      <div>
        <ProgressBar progress={progress} />
        {status !== 'approved' && (
          <Button
            className={styles['primary-button']}
            label={buttonLabel()}
            onClick={
              status === 'reproved' ? onReprovedDocuments : onEditDocuments
            }
          />
        )}
      </div>
    </div>
  )
}

type StatusProps = {
  status: string
}
type ProgressProps = {
  Icon: IconType
  text: string
  color: string
}
type StatusProgressProps = {
  [key: string]: ProgressProps
}
const Status: React.FC<StatusProps> = ({ status }) => {
  const statusProgressDocumentation: StatusProgressProps = {
    approved: {
      Icon: HiOutlineCheckCircle,
      text: 'Documentação aprovada',
      color: colors.success
    },
    reproved: {
      Icon: FaExclamationCircle,
      text: '04 documentos reprovados',
      color: colors.error
    },
    finished: {
      Icon: HiCheck,
      text: 'Documentação pronta para enviar para validação',
      color: colors.success
    },
    incomplete: {
      Icon: BsExclamation,
      text: 'Documentação incompleta',
      color: colors.error
    }
  }
  const { Icon, text, color } = statusProgressDocumentation[
    status as keyof StatusProgressProps
  ]
  return (
    <>
      <div className={styles.status}>
        <Icon size='24' color={color} />
        <p>{text}</p>
      </div>
    </>
  )
}
