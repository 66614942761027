import React from 'react'

import classnames from 'classnames'


import { XIcon } from 'assets/svg'
import { Button } from 'components'
import { SmallProponentModel } from '_types'
import { Modal } from 'components/modal/Modal'

import styles from './DocumentationListModal.module.scss'

type DocumentationListModalProps = {
  isOpen: boolean
  closeModal: () => void
  proponent: SmallProponentModel
}

export const DocumentationListModal: React.FC<DocumentationListModalProps> = ({
  isOpen,
  closeModal,
  proponent
}) => {
  const button = proponent.statusDocumentation.proponents.some(
    document => document.status === 'reproved'
  )

  return (
    <Modal
      className={styles.modal}
      typeModal='fullscreen'
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <div className={styles.container}>
        <header>
          <div className={styles.icon}>
            <XIcon width='24' height='24' onClick={closeModal} />
          </div>
          <div>
            <h1>Documentos requisitados</h1>
            <h2>{proponent.name}</h2>
          </div>
        </header>

        <div className={styles.documents}>
          <section className={styles.itens}>
            <h3>Documentos do proponente</h3>
            <ul>
              {proponent.statusDocumentation.proponents.map(document => (
                <li
                  key={document.title}
                  className={classnames({
                    [styles.reproved]: document.status === 'reproved',
                    [styles.pending]: document.status === 'pending'
                  })}
                >
                  <p>{document.title}</p>
                  {document.status === 'reproved' && (
                    <strong>Documento reprovado</strong>
                  )}
                  {document.status === 'pending' && (
                    <strong>Documento em validação</strong>
                  )}
                  {document.reason && <span>Motivo: {document.reason}</span>}
                </li>
              ))}
            </ul>
          </section>

          <section className={styles.itens}>
            <h3>Documentos do cônjuge</h3>
            <ul>
              {proponent.statusDocumentation.spouse.map(document => (
                <li
                  key={document.title}
                  className={classnames({
                    [styles.reproved]: document.status === 'reproved',
                    [styles.pending]: document.status === 'pending'
                  })}
                >
                  <p>{document.title}</p>
                  {document.status === 'reproved' && (
                    <strong>Documento reprovado</strong>
                  )}
                  {document.status === 'pending' && (
                    <strong>Documento em validação</strong>
                  )}
                  {document.reason && <span>Motivo: {document.reason}</span>}
                </li>
              ))}
            </ul>
          </section>

          <section className={styles.itens}>
            <h3>Documentos complementares</h3>
            <ul>
              {proponent.statusDocumentation.complementary.map(document => (
                <li
                  key={document.title}
                  className={classnames({
                    [styles.reproved]: document.status === 'reproved',
                    [styles.pending]: document.status === 'pending'
                  })}
                >
                  <p>{document.title}</p>
                  {document.status === 'reproved' && (
                    <strong>Documento reprovado</strong>
                  )}
                  {document.status === 'pending' && (
                    <strong>Documento em validação</strong>
                  )}
                  {document.reason && <span>Motivo: {document.reason}</span>}
                </li>
              ))}
            </ul>
          </section>
        </div>
        <div className={styles.button}>
          {button && <Button label='Anexar documentos' />}
        </div>
      </div>
    </Modal>
  )
}
