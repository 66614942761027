import faker from '@faker-js/faker'

import { mockProposal } from '_mocks'
import { ProposalModel } from '_types'
import { freeze } from 'utils/freeze/freeze'

import {
  CancelProposal,
  GetProposalData,
  ListProposal,
  SaveProposalDraft,
  SendProposalToAnalysis
} from './types'

const storage = [
  { ...mockProposal(), id: '75fa2d0e-c502-46d6-88c5-60f78448e007' },
  { ...mockProposal(), id: '91a76076-701c-4199-98ae-e3138bc7ed55' },
  { ...mockProposal(), id: '6b5e61e6-a65b-444b-895b-6e9ccd027e03' }
]

export const listProposal: ListProposal = async () => {
  await freeze()
  return storage
}

export const getProposalData: GetProposalData = async ({ id }) => {
  await freeze()
  const findedProposal = storage.find(p => p.id === id)
  if (!findedProposal) throw new Error('ProposalNotFound')
  return findedProposal
}

export const saveProposalDraft: SaveProposalDraft = async (
  params: ProposalModel
) => {
  await freeze()
  const completeProposal = { ...params, id: faker.datatype.uuid() }
  storage.push(completeProposal)
  return completeProposal
}

export const sendProposalToAnalysis: SendProposalToAnalysis = async (
  params: ProposalModel
) => {
  await freeze()
  const completeProposal = { ...params, id: faker.datatype.uuid() }
  storage.push(completeProposal)
  return completeProposal
}

export const cancelProposal: CancelProposal = async ({ id }) => {
  await freeze()
  const findedProposal = storage.find(p => p.id === id)
  if (!findedProposal) throw new Error('ProposalNotFound')
  findedProposal.status = 'canceled'
}
