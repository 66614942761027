import { useCallback, useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { emptyProposal } from '_mocks'
import { ProposalModel } from '_types'
import { NEW_RESOURCE_KEY, Routes } from 'router/routes'
import { getProposalData, sendProposalToAnalysis } from 'services/api'
import { useProposal } from 'store/hooks'
import { changeProposal } from 'store/proposal/actions'

import { ProposalStepType } from './containers'

export type UseProposalPage = {
  proposalStep: ProposalStepType
  tutorialIsOpen: boolean
  onTutorialFinish: () => void
  handleChangeStep: (step: ProposalStepType) => void
  loading: boolean
  handleSendProposal: (proposal: ProposalModel) => void
}

export function useProposalPage(): UseProposalPage {
  const { dispatch } = useProposal()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const [proposalStep, setProposalStep] = useState<ProposalStepType>(() => {
    const step = new URL(window.location.href).searchParams.get('step')
    return (Number(step) as ProposalStepType) || 1
  })

  const [tutorialIsOpen, setTutorialOpen] = useState(
    () => !localStorage.getItem('showed_tutorial')
  )

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const isCreatingProposal = id === NEW_RESOURCE_KEY
      if (isCreatingProposal) return dispatch(changeProposal(emptyProposal()))

      const response = await getProposalData({ id })
      dispatch(changeProposal(response))
    } catch (error) {
      toast.error('Error ao carregar dados da proposta')
      history.push(Routes.PROPOSALS())
    } finally {
      setLoading(false)
    }
  }, [id, history, dispatch])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onTutorialFinish = () => {
    localStorage.setItem('showed_tutorial', 'true')
    setTutorialOpen(false)
  }

  const handleChangeStep = (step: ProposalStepType) => {
    setProposalStep(step)
  }

  const handleSendProposal = async (proposal: ProposalModel) => {
    setLoading(true)
    await sendProposalToAnalysis(proposal)
    setLoading(false)
    history.push(Routes.PROPOSALS())
  }

  return {
    proposalStep,
    tutorialIsOpen,
    onTutorialFinish,
    handleChangeStep,
    loading,
    handleSendProposal
  }
}
