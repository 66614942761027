import React, { useState } from 'react'

import classnames from 'classnames'
import { IoIosArrowForward } from 'react-icons/io'

import { GenericListDocuments, SmallProponentModel } from '_types'
import alertIcon from 'assets/svg/alerta.svg'
import { BottomNavigation, Button } from 'components'

import styles from './DocumentationForm.module.scss'
import { PersonalStep } from './steps/PersonalStep'
import { SpouseStep } from './steps/SpouseStep'

const Steps = {
  PERSONAL: '__PERSONAL__',
  SPOUSE: '__SPOUSE__',
  COMPLEMENTARY: '__COMPLEMENTARY__'
}

type DeclinedDocumentationForm = {
  proponent: SmallProponentModel
  handleCloseForm: () => void
  isVisibleHeader?: boolean
}

export const DeclinedDocumentationForm: React.FC<DeclinedDocumentationForm> = ({
  proponent,
  handleCloseForm,
  isVisibleHeader = true
}) => {
  const [activeStep, setActiveStep] = useState(Steps.PERSONAL)

  const getReprovedDocuments = (docs: GenericListDocuments) =>
    Object.entries(docs).reduce((acc, [key, value]) => {
      if (value.status === 'reproved') return { ...acc, [key]: value }
      return acc
    }, {})

  const personalReprovedDocuments = getReprovedDocuments(
    proponent.personalDocuments
  )

  const spouseReprovedDocuments = getReprovedDocuments(
    proponent.spouseDocuments
  )

  const stepsContent = {
    [Steps.PERSONAL]: (
      <PersonalStep
        personalDocuments={personalReprovedDocuments}
        idProponent={proponent.id}
      />
    ),
    [Steps.SPOUSE]: (
      <SpouseStep
        spouseDocuments={spouseReprovedDocuments}
        idProponent={proponent.id}
      />
    )
  }

  const stepsNav = {
    [Steps.PERSONAL]: {
      title: `Documentos pessoais - ${
        Object.keys(personalReprovedDocuments).length
      }/5`
    },
    [Steps.SPOUSE]: {
      title: `Documentos do cônjuge - ${
        Object.keys(spouseReprovedDocuments).length
      }/5`
    }
  }

  function onChangeStep(step: string) {
    setActiveStep(step)
  }

  function activeStepClassName(step: string) {
    return activeStep === step ? styles.activeStep : ''
  }

  function onNextStep() {
    if (activeStep === Steps.PERSONAL) return setActiveStep(Steps.SPOUSE)
    if (activeStep === Steps.SPOUSE) return handleCloseForm()
  }

  function buttonLabel() {
    if (activeStep === Steps.PERSONAL) return 'Próximo'
    return 'Fechar'
  }

  return (
    <div className={styles.container}>
      <div className={classnames({ [styles.none]: !isVisibleHeader })}>
        <div className={styles.title}>
          <img src={alertIcon} alt='Documentos reprovados' />
          <h1>Documentos Reprovados - {proponent.name}</h1>
        </div>
        <header>
          <span>
            Você pode arrastar seus documentos para a área indicada ou clicar
            para carregar arquivos do seu computador
          </span>
          <p>
            O arquivo não deve exceder 25mb. Formatos aceitos: PDF, JPG, PNG.
          </p>
        </header>
      </div>
      <nav>
        {Object.entries(stepsNav).map(([step, { title }]) => (
          <button
            key={step}
            className={activeStepClassName(step)}
            onClick={() => onChangeStep(step)}
          >
            <div>{title}</div>
          </button>
        ))}
      </nav>

      {stepsContent[activeStep]}

      <div className={styles.bottomNavigation}>
        <BottomNavigation
          mainButton={
            <Button
              label={String(buttonLabel())}
              onClick={onNextStep}
              icon={<IoIosArrowForward size='16' />}
            />
          }
          secondaryButton={
            <Button
              typeButton='underlined'
              label='Salvar e finalizar depois'
              onClick={handleCloseForm}
            />
          }
        />
      </div>
    </div>
  )
}
