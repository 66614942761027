import React, { useEffect, useState, useCallback } from 'react'

import classnames from 'classnames'
import { IoIosClose, IoIosCheckmark } from 'react-icons/io'
import { RiArrowDropDownLine } from 'react-icons/ri'

import color from 'assets/styles/partials/variables.scss'
import { applyMask } from 'utils/formatters/mask'
import { sizeStyle } from 'utils/sizeStyle'

import styles from './Input.module.scss'

export type InputProps = {
  value: string
  onChange?: (
    value: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  label?: string
  type?: string
  required?: boolean
  isValid?: (value: string) => boolean
  mask?: string
  placeholder?: string
  capitalize?: boolean
  onFocus?: () => void
  disabled?: boolean
  readOnly?: boolean
  size?: sizeStyle
  select?: boolean
  rules?: string[]
  touched?: boolean
  validateOnBlur?: boolean
  className?: string
  rows?: number
}

export const Input: React.FC<InputProps> = ({
  value,
  label = '',
  type = 'text',
  required,
  placeholder,
  onChange = () => null,
  isValid,
  mask,
  capitalize = false,
  size = 'max',
  select = false,
  rules = ['Campo inválido'],
  touched,
  className,
  ...props
}) => {
  const [valid, setValid] = useState<boolean | null>(null)

  const [internalTouch, setInternalTouch] = useState(touched)
  useEffect(() => setInternalTouch(touched), [touched])

  const hasError = !valid && valid !== null

  const validate = useCallback(
    (value = '') => {
      const isValidField = isValid ? isValid(value) : !!value

      if (internalTouch) {
        setValid(isValidField)
      }
    },
    [internalTouch, isValid]
  )

  const onChangeValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newValue = event.target.value

    if (mask) {
      newValue = applyMask(event.target.value, mask)
    }

    validate(newValue)
    onChange(newValue, event)
  }

  useEffect(() => {
    validate(value)
  }, [value, internalTouch, validate])

  const containerInputClasses = classnames({
    [styles.inputElement]: true,
    [className || '']: true,
    [styles.invalid]: !!hasError,
    [styles.valid]: !!valid,
    [styles.area]: type === 'textarea'
  })

  return (
    <div className={`${styles.inputContainer} ${styles[size]}`}>
      <label>
        <p>
          {label}
          {required && '*'}
        </p>
        <div className={containerInputClasses}>
          {type !== 'textarea' && (
            <input
              // eslint-disable-next-line
              role='input'
              value={value}
              type={type}
              placeholder={placeholder}
              onChange={onChangeValue}
              onBlur={() => setInternalTouch(true)}
              required
              className={classnames({ [styles.capitalized]: capitalize })}
              {...props}
            />
          )}

          {type === 'textarea' && (
            <textarea
              value={value}
              placeholder={placeholder}
              onChange={onChangeValue}
              onBlur={() => setInternalTouch(true)}
              required
              className={classnames({ [styles.capitalized]: capitalize })}
              {...props}
            />
          )}

          {select && !(hasError || valid) && <RiArrowDropDownLine size={24} />}
        </div>
        {hasError && (
          <ul className={styles.errorMessage}>
            <p>
              *{' '}
              {rules.map(
                (rule, index) =>
                  `${rule}${index < rules.length - 1 ? ', ' : '.'}`
              )}
            </p>
          </ul>
        )}
      </label>

      {hasError && (
        <IoIosClose
          title='invalid icon'
          color={color.error}
          className={styles.icon}
          size={30}
        />
      )}
      {valid && (
        <IoIosCheckmark
          title='valid icon'
          color={color.success}
          className={styles.icon}
          size={30}
        />
      )}
    </div>
  )
}
