import faker from '@faker-js/faker'

import {
  ProposalModel,
  ResourceOriginStatement,
  TimelineItem,
  ProposalDetails
} from '_types'

import { mockProperty, mockProponent } from '.'

export const mockResourceOriginStatement = (): ResourceOriginStatement => ({
  originType: 'financialApplication',
  name: faker.name.findName(),
  data: {
    bank: faker.company.companyName(),
    accountType: 'Conta corrente',
    account: '2356-6',
    agency: '0125',
    value: 'R$ 300.000'
  }
})

export const emptyResourceOriginStatement = (): ResourceOriginStatement => ({
  originType: '',
  name: '',
  data: {
    bank: '',
    accountType: '',
    account: '',
    agency: '',
    value: ''
  }
})

export const emptyProposal = (): ProposalModel => ({
  id: '',
  status: 'pending',
  statusProgress: 0,
  date: new Date(),
  details: [],
  proposalNumber: 0,
  queue: null,
  paymentType: '',
  proposalValue: 0,
  propertyValue: 0,
  comissionValue: 0,
  serviceCharge: 0,
  totalValue: 0,
  paymentDetails: {
    input: 0,
    balance: 0,
    installments: 1
  },
  fees: [
    { comissionValue: 0, whoPay: '-', target: '-', when: '-' },
    { comissionValue: 0, whoPay: '-', target: '-', when: '-' }
  ],
  observations: '',
  resourceOriginStatements: [],
  property: mockProperty(),
  proponents: [],
  proposalDetails: []
})

const mockStorageProposalDetails = (): TimelineItem[] => {
  return faker.random.arrayElement([
    [
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Documentos recebidos',
        status: 'accepted'
      },
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Proposta criada',
        status: 'accepted'
      },
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Cadastro',
        status: ''
      }
    ],
    [
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Documentos recebidos',
        status: 'accepted'
      },
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Proposta criada',
        status: 'accepted'
      },
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Cadastro',
        status: 'accepted'
      },
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Condições definidas',
        status: 'accepted'
      },
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Assinatura da proposta',
        status: 'accepted'
      },
      {
        id: faker.datatype.uuid(),
        date: faker.date.recent(),
        title: 'Documentos reprovados',
        status: 'disapproved'
      }
    ]
  ])
}

export const mockProposal = (): ProposalModel => {
  return {
    ...emptyProposal(),
    id: faker.datatype.uuid(),
    proposalNumber: faker.datatype.number({ min: 0, max: 1000 }),
    status: faker.random.arrayElement([
      'pending',
      'waiting_signature',
      'sent_documents',
      'reproved_documents',
      'canceled'
    ]),
    statusProgress: faker.datatype.number({ min: 0, max: 100 }),
    resourceOriginStatements: [mockResourceOriginStatement()],
    property: mockProperty(),
    proponents: [
      mockProponent(),
      mockProponent(),
      mockProponent(),
      mockProponent()
    ],
    details: [faker.datatype.number({ min: 0, max: 100 })],
    proposalDetails: mockStorageProposalDetails()
  }
}

export const dataDetails = (numberProposal: string): ProposalDetails => {
  return {
    numberProposal: numberProposal,
    position: 2,
    dataTime: mockStorageProposalDetails(),
    property: mockProperty(),
    notification: [
      { key: 'sms', label: 'SMS', value: false, disabled: false },
      { key: 'whatsapp', label: 'Whatsapp', value: false, disabled: false },
      { key: 'email', label: 'E-mail', value: false, disabled: false },
      { key: 'push', label: 'Push Notification', value: false, disabled: false }
    ]
  }
}
