import React from 'react'

import { Proposal, HeaderDateFilter, Loader } from 'components'
import { BaseLayoutWithSideMenu } from 'template'

import styles from './ProposalList.module.scss'
import { useProposalList } from './useProposalList'

export const ProposalListPage: React.FC = () => {
  const { proposals, loading, handleCancelProposal } = useProposalList()

  if (loading) {
    return (
      <BaseLayoutWithSideMenu>
        <Loader />
      </BaseLayoutWithSideMenu>
    )
  }

  return (
    <BaseLayoutWithSideMenu>
      <div className={styles.container}>
        <HeaderDateFilter totalItems={proposals.length} />
        {proposals.map(proposal => (
          <Proposal
            key={proposal.id}
            proposal={proposal}
            cancelProposal={handleCancelProposal}
          />
        ))}
      </div>
    </BaseLayoutWithSideMenu>
  )
}
