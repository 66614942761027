import React from 'react'

import classnames from 'classnames'

import atendimento from 'assets/svg/atendimento.svg'
import curvedArrow from 'assets/svg/curvedArrow.svg'
import { Tutorial, Navigation, BankBanner, AddProponent } from 'components'
import { ForwardedTutorialProps } from 'components/tutorial/Tutorial'
import { PropertyContainer } from 'containers'
import proposalPageStyles from 'pages/proposal/Proposal.module.scss'
import { BaseLayout } from 'template'

import { StepContainer } from '../'
import { ProponentTutorialContainer } from './proponentTutorialContainer/ProponentTutorialContainer'
import styles from './ProposalTutorialContainer.module.scss'

type StepTutorialProps = ForwardedTutorialProps

const Proponent = () => {
  return (
    <>
      Preencha os dados da etapa proponente, incluindo cônjuge, caso houver.
      Para compras coletivas, preencha os dados de todos os proponentes.
      <br />
      <p>
        Para adicionar proponentes, clique no box ,{' '}
        <strong>&apos;Adicionar proponente &apos;</strong>
      </p>
    </>
  )
}
const Painel = () => {
  return (
    <>
      Na barra superior, acesse o painel com suas propostas e documentos ou
      edite seu perfil. Se tiver dúvidas em qualquer etapa do processo, clique
      no ícone de suporte no canto superior direito.
      <br />
      <p>
        <strong>Vamos começar?</strong>
      </p>
    </>
  )
}
const Step: React.FC<StepTutorialProps> = ({
  handleNextStep,
  actualStep,
  ...props
}) => {
  const stepsTextMap: {
    [key: number]: { title: string; description: string | React.ReactNode }
  } = {
    0: {
      title: 'Entenda como a plataforma funciona com a ajuda do nosso tutorial',
      description:
        'O processo tem quatro etapas: Proponente, Proposta, Assinatura e Documentação. Você pode se localizar a qualquer momento pela barra de progresso no topo. Você também pode voltar para etapas anteriores caso queira editar alguma informação.'
    },
    1: {
      title: 'Adicionar proponente',
      description: <Proponent />
    },
    2: {
      title: 'Informações sobre o imóvel',
      description:
        'Visualize as principais informações do imóvel que está comprando no card do lado direito da tela. Para ver mais informações, clique em qualquer lugar do card.'
    },
    3: {
      title: 'Meu Painel e Perfil',
      description: <Painel />
    }
  }

  return (
    <div
      className={`${styles.container} ${
        styles[`step-${actualStep}` as keyof typeof styles]
      }`}
    >
      <div className={styles.shadow} />
      <BaseLayout>
        <div className={proposalPageStyles.container}>
          <BankBanner />

          <div className={actualStep === 0 ? styles.highlight : ''}>
            <StepContainer proposalStep={1} handleChangeStep={() => null} />
          </div>
          <main className={proposalPageStyles.main}>
            <>
              <img
                className={classnames([
                  styles.highlight,
                  styles.arrow,
                  styles[`arrow-${actualStep}` as keyof typeof styles]
                ])}
                src={curvedArrow}
                alt='seta curva'
              />
              <ProponentTutorialContainer isVisible={actualStep === 1} />
              {actualStep === 1 && (
                <div
                  className={classnames([
                    [styles.addProponent],
                    [styles.highlight]
                  ])}
                >
                  <AddProponent />
                </div>
              )}
              <div
                className={classnames({
                  [proposalPageStyles.card]: true,
                  [styles.highlight]: actualStep === 2
                })}
              >
                <PropertyContainer />
              </div>
            </>
          </main>
        </div>
        <div
          className={classnames(
            [styles.navigation],
            [styles[`navigation-${actualStep}` as keyof typeof styles]]
          )}
        >
          <div className={styles.container}>
            <img src={atendimento} alt='icone de atendimento para ajuda' />
            <h1>{stepsTextMap[actualStep as number].title}</h1>
            <p>{stepsTextMap[actualStep as number].description}</p>
          </div>

          <Navigation
            handleNextStep={handleNextStep}
            totalSteps={4}
            actualStep={actualStep}
            {...props}
          />
        </div>
      </BaseLayout>
    </div>
  )
}

type ProposalTutorialContainerProps = {
  onCloseTutorial: () => void
}

export const ProposalTutorialContainer: React.FC<ProposalTutorialContainerProps> = ({
  onCloseTutorial
}) => {
  return (
    <div className={styles.container}>
      <Tutorial isVisible hideTutorial={onCloseTutorial}>
        <Step />
        <Step />
        <Step />
        <Step />
      </Tutorial>
    </div>
  )
}
