import React from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import {
  SavedProponentsPage,
  ProfilePage,
  ProposalPage,
  FaqPage,
  ProposalListPage,
  ProposalDetailsPage,
  FilesUploadPage
} from 'pages'

import { Routes } from './routes'

export function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={Routes.FILES_TO_UPLOAD()} exact>
          <FilesUploadPage />
        </Route>
        <Route path={Routes.PROPOSAL(':id')} exact>
          <ProposalPage />
        </Route>
        <Route path={Routes.PROPONENTS()} exact>
          <SavedProponentsPage />
        </Route>
        <Route path={Routes.PROFILE()} exact>
          <ProfilePage />
        </Route>
        <Route path={Routes.FAQ()} exact>
          <FaqPage />
        </Route>
        <Route path={Routes.PROPOSALS()} exact>
          <ProposalListPage />
        </Route>

        <Route path={Routes.PROPOSALS(':id')} exact>
          <ProposalDetailsPage />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
