import React from 'react'

import classnames from 'classnames'
import { format } from 'date-fns'

import { TimelineItem } from '_types'

import styles from './ProposalTimeline.module.scss'
export type ProposalTimelineProps = {
  data: TimelineItem[]
  onClick: (item: TimelineItem) => void
}

export const ProposalTimeline: React.FC<ProposalTimelineProps> = ({
  data,
  onClick
}) => {
  return (
    <>
      <h1 className={styles.timelineTitle}>Linha do tempo</h1>
      <main>
        <section className={styles.container}>
          {data.map((item, index) => {
            const accepted = item.status === 'accepted'
            const disapproved = item.status === 'disapproved'
            const lastIndex = data.length - 1
            const nextIndex = index + 1
            const isAcceptedSeparator =
              index === lastIndex
                ? false
                : data[nextIndex].status === 'accepted'
            const formatedDate = format(item.date, "dd/MM/yyyy 'às' hh:mm")

            return (
              <article className={styles.item} key={item.id}>
                <p className={styles.date}>{formatedDate}</p>
                <div className={styles.timeline}>
                  <div className={styles.timelineItem}>
                    <div
                      className={classnames(styles.icon, {
                        [styles.accepted]: accepted,
                        [styles.disapproved]: disapproved
                      })}
                    />
                  </div>
                  <div
                    className={classnames(styles.separator, {
                      [styles.accepted]: isAcceptedSeparator,
                      [styles.disapproved]: disapproved
                    })}
                  />
                </div>
                <p
                  className={classnames(styles.title, {
                    [styles.bold]: disapproved,
                    [styles.initial]: !item.status
                  })}
                >
                  {item.title}
                  {disapproved && (
                    <button
                      className={styles.option}
                      onClick={() => onClick(item)}
                    >
                      Ver detalhes
                    </button>
                  )}
                </p>
              </article>
            )
          })}
        </section>
      </main>
    </>
  )
}
