import React from 'react'

import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

import styles from './SideMenu.module.scss'

const options = [
  {
    name: 'Propostas',
    to: '/propostas'
  },
  {
    name: 'Proponentes salvos',
    to: '/proponentes'
  },
  {
    name: 'Meu perfil',
    to: '/perfil'
  },
  {
    name: 'Dúvidas',
    to: '/faq'
  }
]

type SideMenuProps = {
  show?: boolean
  onClose?: () => void
}

export const SideMenu: React.FC<SideMenuProps> = ({
  show = false,
  onClose = () => null
}) => {
  const content = (
    <div
      className={classnames(styles.sideMenu, {
        [styles.shown]: show
      })}
    >
      <nav>
        <ul>
          {options.map((element, id) => {
            return (
              <li key={id}>
                <NavLink to={element.to} activeClassName={styles.select}>
                  {element.name}
                </NavLink>
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
  return (
    <div
      className={classnames(styles.ground, { [styles.overlay]: show })}
      onClick={e => {
        e.stopPropagation()
        onClose()
      }}
    >
      {content}
    </div>
  )
}
