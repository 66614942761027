import React, { useState } from 'react'

import classnames from 'classnames'
import { HiPlus } from 'react-icons/hi'

import colors from 'assets/styles/partials/variables.scss'
import { Input, Button } from 'components'

import styles from './AddDocument.module.scss'

export type AddDocumentProps = {
  onClick: (subtitle: string) => void
}

export const AddDocument: React.FC<AddDocumentProps> = ({ onClick }) => {
  const [subtitle, setSubtitle] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const handleChangeSubtitle = (value: string) => {
    setSubtitle(value)
  }
  const handleChangeIsSubtitle = () => {
    setIsVisible(!isVisible)
  }
  const handleSaveSubtitle = (subtitle: string) => {
    if (!subtitle.length) return
    onClick(subtitle)
    setSubtitle('')
    setIsVisible(false)
  }

  return (
    <button type='button' className={styles.container}>
      {!isVisible && (
        <>
          <div
            className={styles.icon}
            onClick={handleChangeIsSubtitle}
            data-testid='addDocument'
          >
            <HiPlus size={35} color={colors['primary-color']} />
          </div>
          <h1>Adicionar novo documento</h1>
        </>
      )}
      {isVisible && (
        <>
          <Input
            type='text'
            label='Título do documento'
            value={subtitle}
            onChange={handleChangeSubtitle}
          />
          <div className={styles.footer}>
            <Button
              typeButton='underlined'
              label='Cancelar'
              onClick={handleChangeIsSubtitle}
            />
            <button
              className={classnames(styles.save, {
                [styles.disabled]: !subtitle.length
              })}
              onClick={() => handleSaveSubtitle(subtitle)}
            >
              <p>Salvar</p>
            </button>
          </div>
        </>
      )}
    </button>
  )
}
